import React, { useState, useEffect } from "react";
import TabulatorTable from "./TabulatorTable"
import { goRadialAppSearchesColumns } from "./utils/tabulator-columns"
import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from './utils/aws-exports';
import { ReactTabulator } from "react-tabulator";
import styled from 'styled-components'
Amplify.configure(awsmobile)

const TabulatorWrapper = styled.div`
width: 95%;
 margin: 0 auto;
 padding-top: 8vh;
`

const GoRadialAppSearches = (props) => {

    const [inputText, setInputText] = useState("text");
    const [data, setData] = useState([]);

    // const handleClick = (e) => {
    //     setProjectNumber(inputText)
    // }

    const handleTextChange = (e) => {
        setInputText(e.target.value)
    }

    async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
            },
        });
        try {
            const body = await response.json();
            return body;
        }
        catch {
            return 0
        }

    }

    useEffect(() => {

        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getradialappsearches`, sessionData.idToken.jwtToken).then(
                (res) => {
                    // document.activeElement.blur()
                    console.log(res)
                    setData(res)
                }
            ).catch(() => {
            })
        })



    }, [])

    const options = {
        placeholder: "loading...",
        maxHeight: "80vh"
    }

    return (
        <TabulatorWrapper onClick={() => props.setOpen(false)}>
            <div style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <ReactTabulator data={data} columns={goRadialAppSearchesColumns} options={options} />
            </div>
        </TabulatorWrapper>
    )
}

export default GoRadialAppSearches
