import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
const MenuItem = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 8px;
  transition: all 150ms ease;
  color: #dadce1;
  width: 100%;
  padding: 0.5rem;
  font-weight: bold;
  width: 300px;
  margin: 0 1rem;
  border: 0;
  outline: 0;
  cursor: pointer;
  &:hover {
  background: #3a3c3f;
  z-index: 99999;
}
`

const IconButtonSpan = styled.span`
  width: calc(50px * 0.65);
  height: calc(50px * 0.65);
  background-color: #3a3c3f;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease;
  border: 0;
  outline: 0;
  cursor: pointer;
  svg {
  fill: #dadce1;
  width: 24px;
  height: 24px;
}
`

const IconRight = styled.span`
margin-left: auto;
svg {
  fill: #dadce1;
}
`

function DropdownItem(props) {
  return (
    <MenuItem
      onClick={(e) => {
        props.goToMenu && props.setActiveMenu(props.goToMenu)
        if (props.path) {
          props.history.push(props.path)
          props.setOpen(false)
        }
        //   props.history.push('/workmap')
        // console.log(e.target.children[1].getAttribute('path'))
      }}
    >
      <IconButtonSpan>{props.leftIcon}</IconButtonSpan>
      {props.children}
      <IconRight>{props.rightIcon}</IconRight>
    </MenuItem>
  );
}

export default withRouter(DropdownItem)
