import React, { useState, useEffect } from "react";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
// import ProjectsMapCircle from './ProjectsMapCircle';
import Login from "./Login";
// import Amplify, { Auth } from 'aws-amplify';
// import { awsmobile } from './utils/aws-exports';
import GlobalStyle from "./GlobalStyle";
import WorkMAP from "./Workmap";
// Amplify.configure(awsmobile)

//import Loadable from 'react-loadable'
import Library from "./Library";
import { SpinnerRoundOutlined } from "spinners-react";

import Amplify, { Auth } from "aws-amplify";
import { awsmobile } from "./utils/aws-exports";
import GoRadialAppLogins from "./GoRadialAppLogins";
import NavBarComponent from "./NavBarComponent";
import MissingLatLon from "./MissingLatLon";
import GoRadialAppUsers from "./GoRadialAppUsers";
import GoRadialAppSearches from "./GoRadialAppSearches";
import MapboxMap from "./MapboxMap";
import MapboxMapCluster from "./MapBoxMapCluster";
import BeamPlot from "./BeamPlot";
import Transcribe from "./Transcribe";
import DrawingQA from "./DrawingQA";
import DocUpload from "./DocUpload";
import StaffPlots from "./StaffPlots";
import StaffGoalsList from "./StaffGoalsList";
Amplify.configure(awsmobile);

// const LoadableTabulatorTable = Loadable({loader: () => import('./TabulatorTable'),   loading() {
//   return <div>Loading...</div>
// }});
// const LoadableProjectsMapCircle = Loadable({loader: () => import('./ProjectsMapCircle'),  loading() {
//   return <div>Loading...</div>
// }});
// const LoadableImageGrid = Loadable({loader: () => import('./ImageGrid'),  loading() {
//   return <div>Loading...</div>
// }});

function App() {
  const [data, setData] = useState([]);
  const [pageMode, setPageMode] = useState("map");
  const [searchTerm, setSearchTerm] = useState("");
  const [brightness, setBrightness] = useState(1);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedInUsername, setLoggedInUsername] = useState(null);
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [projectNumber, setProjectNumber] = useState("03050");
  const [selectedProject, setSelectedProject] = useState(null);
  const [open, setOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(true);

  async function callBackendAPIWithKey(url, authToken) {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
        "API-Key": "gPESEeorGgabkLvDMO8Q9EZ1fdxbbWSNc+3kGN5QQIk=",
      },
    });
    try {
      const body = await response.json();
      // setBrightness(1)
      return body;
    } catch {
      return 0;
    }
  }

  async function callBackendAPIWithKeyDevApp(url) {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "API-Key": "gPESEeorGgabkLvDMO8Q9EZ1fdxbbWSNc+3kGN5QQIk=",
      },
    });
    try {
      const body = await response.json();
      // setBrightness(1)
      return body;
    } catch {
      return 0;
    }
  }

  // useEffect(() => {
  //   setData([])
  //   let searchTermCurrentLength = searchTermLength
  //   if (searchTermCurrentLength > 1) {
  //     Auth.currentSession().then(sessionData => {
  //       setBrightness(0.6)
  //       setIsLoading(true)
  //       callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getprojectsworkmap2?searchTerm=${searchTerm}`, sessionData.idToken.jwtToken).then(
  //         (res) => {
  //           if (document.getElementById("searchTextBox").value.length === searchTermCurrentLength) {
  //             setCounter(res.length)
  //             // document.activeElement.blur()
  //             console.log(res)
  //             setData(res)
  //             setBrightness(1)
  //             setIsLoading(false)
  //             // callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/addradialtestuserssearch?username=${loggedInUsername}&searchtext=${searchTerm}`, sessionData.idToken.jwtToken).then(
  //             //   (res) => {
  //             //     console.log("query saved")

  //             //   }
  //             // )
  //           }

  //         }
  //       ).catch(() => {
  //         setBrightness(1)
  //         setIsLoading(false)
  //       })
  //     })
  //   }

  // }, [searchTerm, searchTermLength])

  useEffect(() => {
    if (searchTerm.length > 0) {
      setData([]);
      Auth.currentSession().then((sessionData) => {
        setBrightness(0.6);
        setIsLoading(true);
        callBackendAPIWithKeyDevApp(
          `https://workmap-new.vercel.app/api/workmap/projects-dev-app?searchTerm=${searchTerm}`
        )
          .then((res) => {
            setCounter(res.length);
            // document.activeElement.blur()
            console.log(res);
            setData(res);
            setBrightness(1);
            setIsLoading(false);
          })
          .catch(() => {
            setBrightness(1);
            setIsLoading(false);
          });
      });
    }
  }, [searchTerm, showArchived]);

  //   useEffect(() => {

  //     // setBrightness(0.4)
  //     const getSession = async () => {
  // //       await Auth.signIn('radialtestuser', 'RadialT3$t*2021')
  // // .then(success => console.log('successful sign in'))
  // // .catch(err => console.log(err));

  //       // await Auth.signOut()
  //       const session = await Auth.currentSession()
  //       if (session) {
  //         setLoggedIn(true)
  //       }
  //       console.log(session)
  //     }

  //     getSession()

  //   }, [])

  // const handleChange = (e) => {
  //   setSearchTermLength(e.target.value.length)
  //   setSearchTerm(e.target.value)
  // }

  const handleChange = (e) => {
    if (e.length > 0) {
      console.log(e.map((item) => item.value));
      setSearchTerm(e[0].value);
    } else setData([]);
  };

  const handleClick = () => {
    setPageMode((pageMode) => (pageMode === "card" ? "map" : "card"));
  };

  const handleArchivedClick = () => {
    setShowArchived((showArchivedStatus) => !showArchivedStatus);
  };
  return (
    <Router>
      {loggedIn ? (
        <NavBarComponent
          open={open}
          setOpen={setOpen}
          selectedProject={selectedProject}
          handleChange={handleChange}
          handleClick={handleClick}
          counter={counter}
          loggedInUsername={loggedInUsername}
          showArchived={showArchived}
          handleArchivedClick={handleArchivedClick}
        />
      ) : null}
      {loggedIn ? (
        <Switch>
          <Route
            exact
            path="/library"
            render={(props) => (
              <Library
                open={open}
                setOpen={setOpen}
                counter={counter}
                searchTerm={searchTerm}
                data={data}
                pageMode={pageMode}
                handleChange={handleChange}
                handleClick={handleClick}
                setPageMode={setPageMode}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
              />
            )}
          />
          <Route
            exact
            path="/goradialapplogins"
            render={(props) => (
              <GoRadialAppLogins open={open} setOpen={setOpen} />
            )}
          />
          <Route
            exact
            path="/goradialappusers"
            render={(props) => (
              <GoRadialAppUsers open={open} setOpen={setOpen} />
            )}
          />
          <Route
            exact
            path="/goradialappsearches"
            render={(props) => (
              <GoRadialAppSearches open={open} setOpen={setOpen} />
            )}
          />
          <Route
            exact
            path="/transcribe"
            render={(props) => <Transcribe open={open} setOpen={setOpen} />}
          />
          <Route
            exact
            path="/staff-plots"
            render={(props) => <StaffPlots open={open} setOpen={setOpen} />}
          />
          <Route exact path="/drawingqa" render={(props) => <DrawingQA />} />
          <Route
            exact
            path="/workmap/:project_number"
            render={(props) => (
              <WorkMAP
                open={open}
                setOpen={setOpen}
                projectNumber={projectNumber}
                selectedProject={selectedProject}
                searchTerm={searchTerm}
              />
            )}
          />
          <Route
            exact
            path="/missingcoords"
            render={(props) => <MissingLatLon open={open} setOpen={setOpen} />}
          />
          <Route
            exact
            path="/staff-goals"
            render={(props) => <StaffGoalsList open={open} setOpen={setOpen} />}
          />
          <Route
            exact
            path="/mapbox"
            render={(props) => (
              <MapboxMap open={open} setOpen={setOpen} data={data} />
            )}
          />
          <Route
            exact
            path="/mapboxcluster"
            render={(props) => (
              <MapboxMapCluster open={open} setOpen={setOpen} data={data} />
            )}
          />
          <Route
            exact
            path="/beamplot"
            render={(props) => <BeamPlot open={open} setOpen={setOpen} />}
          />
          <Route
            exact
            path="/doc_upload"
            render={(props) => <DocUpload open={open} setOpen={setOpen} />}
          />
          <Redirect from="login" to="/library"></Redirect>
          <Redirect from="*" to="/library"></Redirect>
        </Switch>
      ) : (
        <Switch>
          <Route
            exact
            path="/login"
            render={(props) => (
              <Login
                setLoggedIn={setLoggedIn}
                setLoggedInUsername={setLoggedInUsername}
              />
            )}
          />
          <Redirect from="*" to="/login"></Redirect>
        </Switch>
      )}
      <GlobalStyle />
      {/* </div> */}
      <div
        style={{
          position: "absolute",
          top: "50vh",
          left: "50%",
          filter: "brightness(2)",
        }}
      >
        <SpinnerRoundOutlined
          enabled={isLoading}
          size={61}
          thickness={45}
          speed={168}
          color="#823272"
        />
      </div>
    </Router>
  );
}

export default App;
