import React from "react";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";

const SearchBoxWrapper = styled.div`
  width: 300px;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "black" : "black",
  }),
  control: (base) => ({
    ...base,
    minWidth: 150,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const SearchBoxComponent = (props) => {
  const options = [
    { value: "@all", label: "@all" },
    { value: "timber", label: "timber" },
    { value: "CLT", label: "CLT" },
    { value: "brick", label: "brick" },
    { value: "steel", label: "steel" },
    { value: "concrete", label: "concrete" },
    { value: "prefabricated", label: "prefabricated" },
    { value: "cantilever", label: "cantilever" },
    { value: "residential", label: "residential" },
    { value: "alterations and additions", label: "alterations and additions" },
  ];

  return (
    <SearchBoxWrapper>
      <CreatableSelect
        isMulti
        on
        styles={customStyles}
        id="searchTextBox"
        options={options}
        onChange={props.handleChange}
        formatCreateLabel={(e) => `Search for ${e}`}
      />
    </SearchBoxWrapper>
  );
};

export default SearchBoxComponent;
