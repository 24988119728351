import React, { useState, useEffect } from 'react'
import S3FileUpload from 'react-s3';
import { ReactTabulator } from 'react-tabulator';
import { documentUploadColumns } from "./utils/tabulator-columns"
import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from './utils/aws-exports';
import Swal from 'sweetalert2';

Amplify.configure(awsmobile)


const DocUpload = () => {
  const [data, setData] = useState([])

  async function callBackendAPIWithKey(url, authToken) {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
      },
    });
    try {
      const body = await response.json();
      // setBrightness(1)
      return body;
    }
    catch {
      return 0
    }
  }

  useEffect(() => {

    Auth.currentSession().then(sessionData => {

      callBackendAPIWithKey(`https://workmap.api.radialapp.com/s3_documents`, sessionData.idToken.jwtToken).then(
        (res) => {
          setData(res)
        }
      ).catch(() => {

      })
    })


    // fetch('https://workmap.api.radialapp.com/s3_documents')
    //   .then(response => response.json())
    //   .then(responseData => setData(responseData));

  }, [])

  var config = {
    bucketName: "radial-images",
    dirName: "documents",
    region: "ap-southeast-2",
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };
  const handleDragover = (e) => {

    e.persist()
    e.preventDefault()
  };
  let options = {
    pagination: "local", //enable local pagination.
    paginationSize: 10, // this option can take any positive integer value
    layout: "fitData",
  }

  const handleDrop = (e) => {


    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === "file") {
          var file = e.dataTransfer.items[i].getAsFile();
          console.log("... file[" + i + "].name = " + file.name);
          S3FileUpload.uploadFile(file, config)
            .then((data) => {
              Swal.fire({
                title: ``,
                text: `Do you want to copy ${data.location} to the clipboard?`,
                showDenyButton: true,
                confirmButtonText: 'Copy',
                denyButtonText: `Don't copy`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  navigator.clipboard.writeText(data.location)
                  Swal.fire({
                    icon: 'success',
                    title: ``,
                    timer: 1000,
                    text: `Copied`,
                    showConfirmButton: false,

                  })
                }
              })
              Auth.currentSession().then(sessionData => {

                callBackendAPIWithKey(`https://workmap.api.radialapp.com/s3_documents`, sessionData.idToken.jwtToken).then(
                  (res) => {
                    setData(res)
                  }
                ).catch(() => {

                })
              })
              // alert("Here is a link to your hosted file: " + data.location)



            })
            .catch((err) => console.error(err));
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        console.log('file[' + i + '].name = ' + e.dataTransfer.files[i].name);
        S3FileUpload.uploadFile(file, config)
          .then((data) => console.log(data))
          .catch((err) => console.error(err));
      }
    }
  };
  return (
    <div style={{ display: "flex" }}>
      <div onDrop={handleDrop} onDragOver={handleDragover} ondr className="content-drop-zone" style={{ height: "100vh", width: "50vw", backgroundColor: "#A2DBFA", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "30px" }}>
        <br />Drop file here...

      </div>
      <div style={{ backgroundColor: "#F0F8FF", width: "50vw", display: "flex", justifyContent: "center", alignItems: "center" }}>            <div style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
        <ReactTabulator data={data} columns={documentUploadColumns} options={options} />
      </div></div>

    </div>
  )
}

export default DocUpload
