import React, { useState } from 'react'
import EditableList from 'react-list-editable';
import 'react-list-editable/lib/react-list-editable.css';

const SearchWordsList = ({ wordsList, setWordsList }) => {

  const handleListChange = (newList) => {
    setWordsList(newList)
  }

  return (
    <div className='app-container' style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
      <EditableList
        list={wordsList}
        onListChange={handleListChange}
        placeholder='Enter a value'
      />
    </div>
  );
}

export default SearchWordsList