import React from 'react'
import ReactMapGL, {Marker} from 'react-map-gl';
import styled from 'styled-components'

const MapBoxWrapper = styled.div`
 padding-top: 5vh;
`

const MapboxMap = (props) => {
    const [viewport, setViewport] = React.useState({
        latitude: -33.8615,
        longitude: 151.1767,
        zoom: 8
    });

      // Only rerender markers if props.data was changed
  const markers = React.useMemo(() => props.data.map(project => (
    project.latitude  && <Marker
      key={project.value}
      latitude={project.latitude}
      longitude={project.longitude}
    >
        {<img src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" alt=""/>}
        </Marker>
    )
  ), [props.data]);

    return (
        <MapBoxWrapper>
            <ReactMapGL
                {...viewport}
                width="100%"
                height="95vh"
                onViewportChange={(viewport) => setViewport(viewport)}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN}
                mapStyle="mapbox://styles/radialsolutions/ckmg4szeg57ef17ody7mxv289"
            >
                {markers}
                        {/* {props.data.map(project => (
          project.latitude  && <Marker
            key={project.value}
            latitude={project.latitude}
            longitude={project.longitude}
          >
              {<img src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" alt=""/>}
              </Marker>
        )
        )} */}
                </ReactMapGL>
        </MapBoxWrapper>
    );
}

export default MapboxMap
