import React, { useState, useEffect } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from './utils/aws-exports';
import styled from 'styled-components'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { ReactTabulator } from "react-tabulator";
import { transcribeColumns } from "./utils/tabulator-columns";
Amplify.configure(awsmobile)

const HeaderWrapper = styled.div`
width: 95%;
 margin: 0 auto;
 padding-top: 8vh;
`

const RecordWrapper = styled.div`
width: 95%;
 margin: 0 auto;
 padding-top: 4vh;
 display: flex;
 align-items: center;
`

const TabulatorWrapper = styled.div`
width: 95%;
 margin: 0 auto;
 padding-top: 4vh;
 align-items: center;
`

const RecordButton = styled.button`
/* background-color: red; */
color: white;
border-radius: 50%;
outline: 0 !important;
background-color: ${props => props.isRecording ? "blue" : "red"};
min-width: 70px;
min-height: 70px;
`

const Mp3Recorder = new MicRecorder({ bitRate: 320 });

const Transcribe = (props) => {

    const [audioFileBlobUrl, setAudioFileBlobUrl] = useState(null)
    const [isRecording, setIsRecording] = useState(false)
    const [recordLive, setRecordLive] = useState(false)
    const [data, setData] = useState([])

    async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
            },
        });
        try {
            const body = await response.json();
            return body;
        }
        catch {
            return 0
        }

    }

    const handleClick = () => {
        if (isRecording) {
            stop()
        }
        else {
            Mp3Recorder.start().then(() => {
                // something else
            }).catch((e) => {
                console.error(e);
            });
        }
        setIsRecording(!isRecording)
    }

    const stop = () => {
        Mp3Recorder.stop()
            .getMp3()
            .then(([buffer, blob]) => {

                const file = new File(buffer, 'music.mp3', {
                    type: blob.type,
                    lastModified: Date.now()
                });

                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    var base64data = reader.result;

                    Auth.currentSession().then(sessionData => {
                        callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/radial-transcribe-get-presigned-url`, sessionData.idToken.jwtToken).then(
                            (res) => {
                                console.log(res.url)
                                fetch(res.url, {
                                    method: "PUT",
                                    body: file,
                                    headers: { "content-type": "audio/mpeg" }
                                })

                            }
                        ).catch(() => {
                        })
                    })
                };

                const li = document.createElement('div');
                const player = new Audio(URL.createObjectURL(file));
                player.controls = true;
                li.appendChild(player)
                document.querySelector('#audio_player').appendChild(li);
                // player.play();

            })
            .catch((e) => console.log(e));
    };

    const handleUploadClick = () => {
        let file = document.querySelector('input[type=file]').files[0]
        console.log(file)
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            var base64data = reader.result;

            Auth.currentSession().then(sessionData => {
                callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/radial-transcribe-get-presigned-url`, sessionData.idToken.jwtToken).then(
                    (res) => {
                        console.log(res.url)
                        fetch(res.url, {
                            method: "PUT",
                            body: file,
                            headers: { "content-type": "audio/mpeg" }
                        })
                    }
                ).catch(() => {
                })
            })
        };
    }

    const refreshTranscriptions = async () => {

        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/gettranscriptions`, sessionData.idToken.jwtToken).then(
                (res) => {
                    console.log(res)
                    setData(res)
                }
            ).catch(() => {
            })
        })

    }

    useEffect(() => {
        refreshTranscriptions()

    }, [])




    const options = {
        placeholder: "loading...",
        maxHeight: "80vh",
    }

    return (
        <div onClick={() => props.setOpen(false)}>
            <HeaderWrapper>
                <h2>This page allows you to transcribe your speech to text for searching later.</h2>
                <h4>You can either upload an audio file from your device or record your voice live in the browser.</h4>
                <div style={{ display: "flex" }}>
                    <Toggle
                        defaultChecked={false}
                        icons={false}
                        onChange={() => setRecordLive(!recordLive)}
                    />
                    <span style={{ paddingLeft: "10px" }}>{!recordLive ? "Upload file" : "Record live"}</span>
                </div>
            </HeaderWrapper>
            <RecordWrapper>
                {!recordLive ? <div style={{ display: "flex", flexDirection: "column" }}><input type="file" />
                    <button style={{ marginTop: "10px" }} onClick={handleUploadClick}>Upload File</button></div> : <RecordButton isRecording={isRecording} onClick={handleClick}>{isRecording ? "Stop" : "Record"}</RecordButton>}
                <div id="audio_player"></div>
            </RecordWrapper>
            <TabulatorWrapper>
                <button onClick={refreshTranscriptions}>Refresh transcriptions list</button>
                <ReactTabulator data={data} columns={transcribeColumns} options={options} />
            </TabulatorWrapper>
        </div >
    )
}

export default Transcribe
