import React, { useState } from 'react'
import styled, { keyframes } from "styled-components"
import { withRouter } from 'react-router-dom'
import S3FileUpload from 'react-s3';

const Container = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, 250px);
grid-auto-rows: minmax(200px,3fr);
gap: 20px;
justify-content: center;
padding-top: 30px;
padding-bottom: 20px;
text-align: center;
  `

const slidein = keyframes`
from {
  opacity: 0
}

to {
  opacity: 1
}
`

const Card = styled.div`
height: 280px;
position: relative;
background-color: white;
padding-bottom: 5px;
transition: filter 200ms;
box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.1);
border-radius: 3px;
animation-duration: 1s;
animation-name: ${slidein};
&:hover {
  cursor: pointer;
  background: linear-gradient(to bottom, #FFFFFF, #EFFFFF);
}
`

const ArchitectTextDiv = styled.div`
position: absolute;
bottom: 2px;
text-align: center;
width: 100%;
color: rgba(0,0,0,0.4);
`

const ImageGrid = (props) => {

  const [state, setState] = useState({
    currentDraggedProject: "",
  });

  let config = {
    bucketName: "radial-images",
    dirName: "WorkMAP-images/" + state.currentDraggedProject /* optional */,
    region: "ap-southeast-2",
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };

  const handleDrop = (e) => {

    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === "file") {
          var file = e.dataTransfer.items[i].getAsFile();
          console.log("... file[" + i + "].name = " + file.name);
          S3FileUpload.uploadFile(file, config)
            .then((data) => console.log(data))
            .catch((err) => console.error(err));
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        console.log('file[' + i + '].name = ' + e.dataTransfer.files[i].name);
        S3FileUpload.uploadFile(file, config)
          .then((data) => console.log(data))
          .catch((err) => console.error(err));
      }
    }
  };

  // Project image dragover event to prepar for drop for upload to s3
  const handleDragover = (e) => {

    e.persist()
    setState((prevValue) => {
      return {
        ...prevValue,
        currentDraggedProject: e.target.parentElement.childNodes[1].innerHTML.split(" - ")[0]
      };
    });

    config.dirName = "WorkMAP-images/" + e.target.parentElement.childNodes[1].innerHTML.split(" - ")[0]

    e.preventDefault()
  };

  document.title = "Library - Grid";
  return (
    <Container>
      {props.data.map((project) => {
        return <Card onDoubleClick={() => {
          props.setSelectedProject(project)
          props.history.push(`/workmap/${project.value}`)
        }} key={project.value}><img onDragOver={handleDragover} onDrop={handleDrop} src={project.photo_url} alt="central images" width="250" height="200" /><div>{project.label}</div><small><ArchitectTextDiv >{project.architect}</ArchitectTextDiv></small></Card>
      })}
    </Container>
  )
}

export default withRouter(ImageGrid)
