import React, { useState, useEffect } from 'react'
import Plot from 'react-plotly.js'
import styled from 'styled-components'
import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from './utils/aws-exports';
Amplify.configure(awsmobile)

const BeamWrapper = styled.div`
width: 95%;
 margin: 0 auto;
 padding-top: 8vh;
`

const BeamPlot = () => {

  const [plotFigure, setPlotFigure] = useState(null)
  const [showExternal, setShowExternal] = useState(true)
  const [brightness, setBrightness] = useState(1)

  async function callBackendAPIWithKey(url, authToken) {
    // setBrightness(0.4)
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
      },
    });
    try {
      const body = await response.json();
      // setBrightness(1)
      return body;
    }
    catch {
      return 0
    }
  }

  const handleToggle = () => {
    setShowExternal(!showExternal)
  }

  useEffect(() => {
    setBrightness(0.6)
    Auth.currentSession().then(sessionData => {
    callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/test-ecr-lambda`, sessionData.idToken.jwtToken).then(
      (res) => {
        let plotFigureResponse = JSON.parse(res)
        setBrightness(1)
        setPlotFigure(plotFigureResponse)
      })
    })
    // return () => {
    //   cleanup
    // }
  }, [])

  return (
    <BeamWrapper>
      <button onClick={handleToggle}>Toggle external/internal</button>
      {plotFigure && <Plot
        data={showExternal ? plotFigure.externalFigureData : plotFigure.internalFigureData}
        layout={showExternal ? plotFigure.externalFigureLayout : plotFigure.internalFigureLayout}
        style={{ display: "block", width: 900, height: 900, margin: "0 auto" }}
      />
      }
    </BeamWrapper>
  )
}

export default BeamPlot
