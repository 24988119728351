import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Circle, InfoBox } from '@react-google-maps/api';
import {withRouter} from 'react-router-dom'

const containerStyle = {
  width: '100%',
  height: '95vh'
};

const center = {
  lat: -33.8615, lng: 151.1767
};

function ProjectsMapCircle(props) {

  const [map, setMap] = useState(null)
  const [zoom, setZoom] = useState(13)

  const handleZoomChanged = (e) => {

    if(map){
      console.log(map.getZoom())
      setZoom(map.getZoom())
    }

  }

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const mapOptions = {
    gestureHandling: "greedy",
    maxZoom: 13,
    streetViewControl: false
  }

  const circleOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 0.1,
    fillColor: 'blue',
    fillOpacity: 0.35
  }

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={13}
        streetView={false}
        options={mapOptions}
        onLoad={onLoad}
        onZoomChanged={handleZoomChanged}
      >
        {props.data.map(project => (
          <Circle
            key={project.value}
            center={{ "lat": project.latitude, "lng": project.longitude }}
            radius={15 * 156543.03392 / Math.pow(2.0005, zoom)}
            options={circleOptions}
            onClick={(e) => console.log(e.domEvent)}
            onMouseOver={() => {
              props.setSelectedProject(project)
              // setIsProjectSelected(true)
            }}
            onMouseOut={() => {
              props.setSelectedProject(null)
              // setIsProjectSelected(true)
            }}
            onMouseDown={() => props.setSelectedProject(project)}
          />
        )
        )}
                        {props.selectedProject  && (
          <InfoBox
          position={{"lat": props.selectedProject.latitude, "lng": props.selectedProject.longitude}}
          closeBoxURL="">
            <div style={{backgroundColor: "white"}}>
            <img width="200px" height="200px" src={props.selectedProject.photo_url} alt=''/>
            {/* <big><p>{props.selectedProject.label}</p></big> */}
            <p>{props.selectedProject.architect}</p>
            </div>
          </InfoBox>
        )}
        { /* Child components, such as markers, info windows, etc. */}
        <></>
      </GoogleMap>
    </LoadScript>
  )
}

export default ProjectsMapCircle