import React, { useState, useEffect } from "react";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import { ReactTabulator } from "react-tabulator";
import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from './utils/aws-exports';
import { missingLatLonColumns } from './utils/tabulator-columns'
import styled from 'styled-components'
import { Form } from "react-bootstrap";
Amplify.configure(awsmobile)

const TabulatorWrapper = styled.div`
width: 95%;
 margin: 0 auto;
 padding-top: 8vh;
`


const MissingLatLon = (props) => {

    document.title = "Missing Lat/Lon";

    const [data, setData] = useState([]);
    const [isChecked, setIsChecked] = useState(true)
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getmissinglatlon?checked=${isChecked ? "yes" : "no"}&searchTerm=${searchTerm}`, sessionData.idToken.jwtToken).then(
                (res) => {
                    setData(res)
                }
            ).catch(() => {
            })
        })
    }
        , [isChecked])

    async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
            },
        });
        try {
            const body = await response.json();
            return body;
        }
        catch {
            return 0
        }

        // if (response.status !== 200) {
        //   throw Error(body.message);
        // }

    }

    function sendToAWS(url) {
        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(url, sessionData.idToken.jwtToken).then(
                (res) => {
                    console.log("success");
                }
            ).catch(() => {
            })
        })
    }

    function sendToAWS2(url) {
        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(url, sessionData.idToken.jwtToken).then(
                (res) => {
                    console.log(res)
                    setData(res);
                }
            ).catch(() => {
            })
        })
    }

    const cellEdited = (cell) => {
        // console.log(`UPdate workmap set ${cell._cell.column.field} = '${cell._cell.value}' where project_number = '${cell._cell.row.data.project_number}'`)

        sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatelatlon?field=${cell._cell.column.field}&value=${cell._cell.value}&project_number=${cell._cell.row.data.project_number}`)
    }



    const options = {
        placeholder: "loading...",
        maxHeight: "80vh"
    }

    return (
        <TabulatorWrapper onClick={() => props.setOpen(false)}>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control value={searchTerm} onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            sendToAWS2(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getmissinglatlon?checked=${isChecked ? "yes" : "no"}&searchTerm=${searchTerm}`)
                            e.preventDefault();
                        }
                    }} onChange={(e) => setSearchTerm(e.target.value)} disabled={isChecked} type="text" placeholder="Search for a project..." />
                    <Form.Check onChange={() => setIsChecked(!isChecked)} checked={isChecked} type="checkbox" label="Or check this box to show projects with empty coordinates" />
                </Form.Group>
            </Form>
            <ReactTabulator
                // ref={refObj2}
                data={data}
                columns={missingLatLonColumns}
                cellEdited={cellEdited}
                options={options}
            />
        </TabulatorWrapper>
    )
}

export default MissingLatLon
