import React, { useState, useEffect } from "react";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import { ReactTabulator } from "react-tabulator";
import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from './utils/aws-exports';
import { missingLatLonColumns } from './utils/tabulator-columns'
import styled from 'styled-components'
Amplify.configure(awsmobile)

const TabulatorWrapper = styled.div`
width: 95%;
 margin: 0 auto;
 padding-top: 8vh;
`


const StaffGoalsList = (props) => {

    document.title = "Staff goals list";

    const [data, setData] = useState([]);

    useEffect(() => {
        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getstaffgoals`, sessionData.idToken.jwtToken).then(
                (res) => {
                    setData(res)
                }
            ).catch(() => {
            })
        })
    }
        , [])

    async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
            },
        });
        try {
            const body = await response.json();
            return body;
        }
        catch {
            return 0
        }

        // if (response.status !== 200) {
        //   throw Error(body.message);
        // }

    }

    function sendToAWS(url) {
        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(url, sessionData.idToken.jwtToken).then(
                (res) => {
                    console.log("success");
                }
            ).catch(() => {
            })
        })
    }

    const cellEdited = (cell) => {
        // console.log(`UPdate workmap set ${cell._cell.column.field} = '${cell._cell.value}' where project_number = '${cell._cell.row.data.project_number}'`)
        // console.log(cell._cell.row.data.Task_ID)
        sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestaffgoals?field=${cell._cell.column.field}&value=${cell._cell.value}&taskID=${cell._cell.row.data.Task_ID}`)
    }



    const options = {
        placeholder: "loading...",
        maxHeight: "80vh",
        autoColumns: true,
        autoColumnsDefinitions: function (definitions) {
            //definitions - array of column definition objects

            definitions.forEach((column) => {
                if (!['Task_ID', 'Task_name', 'Discipline_letter'].includes(column.field)) {
                    column.editor = true
                }
                else {
                    column.headerFilter = "input"
                }
            });

            return definitions;
        },
    }

    return (
        <TabulatorWrapper onClick={() => props.setOpen(false)}>
            <ReactTabulator
                // ref={refObj2}
                data={data}
                columns={[]}
                cellEdited={cellEdited}
                options={options}
            />
        </TabulatorWrapper>
    )
}

export default StaffGoalsList
