import React, { useState, useEffect } from "react";
import { goRadialAppUsersColumns } from "./utils/tabulator-columns"
import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from './utils/aws-exports';
import { ReactTabulator } from "react-tabulator";
import styled from 'styled-components'
import SkyLight from 'react-skylight';
Amplify.configure(awsmobile)

const TabulatorWrapper = styled.div`
width: 95%;
 margin: 0 auto;
 padding-top: 8vh;
`

const GoRadialAppUsers = (props) => {

    const [inputText, setInputText] = useState("text");
    const [data, setData] = useState([]);
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const usersAddRef = React.useRef(null);
    const usersEditRef = React.useRef(null);

    // const handleClick = (e) => {
    //     setProjectNumber(inputText)
    // }

    async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
            },
        });
        try {
            const body = await response.json();
            return body;
        }
        catch {
            return 0
        }

    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value)
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }

    const handleCreateUser = () => {
        usersAddRef.current.hide()
        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/addusertouserpool?username=${username}&password=${password}`, sessionData.idToken.jwtToken).then(
                () => {
                    callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getgoradialappusers`, sessionData.idToken.jwtToken).then(
                        (res) => {
                            res = res.Users.map(user => ({ "username": user.Username }))
                            setData(res)
                        }
                    ).catch(() => {
                    })
                }
            ).catch(() => {
            })
        })
        setUsername("")
        setPassword("")
    }

    const handleEditUser = () => {
        usersEditRef.current.hide()

        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/edituserpooluser?username=${username}&password=${password}`, sessionData.idToken.jwtToken).then(
                () => {
                    console.log("edited")
                }
            ).catch(() => {
            })
        })
        setUsername("")
        setPassword("")
    }

    const handleDeleteUser = () => {
        usersEditRef.current.hide()
        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/deleteuserpooluser?username=${username}`, sessionData.idToken.jwtToken).then(
                () => {
                    callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getgoradialappusers`, sessionData.idToken.jwtToken).then(
                        (res) => {
                            res = res.Users.map(user => ({ "username": user.Username }))
                            setData(res)
                        }
                    ).catch(() => {
                    })
                }
            ).catch(() => {
            })
        })
        setUsername("")
        setPassword("")
    }

    useEffect(() => {
        Auth.currentSession().then(sessionData => {
            callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getgoradialappusers`, sessionData.idToken.jwtToken).then(
                (res) => {
                    res = res.Users.map(user => ({ "username": user.Username }))
                    // document.activeElement.blur()
                    //console.log(res)
                    setData(res)
                }
            ).catch(() => {
            })
        })



    }, [])

    var myBigGreenDialog = {
        background: 'linear-gradient(to right, #457fca, #5691c8)',
        color: '#ffffff',
        width: '300px',
        height: '100px',
        marginTop: '-300px',
        marginLeft: '-150px',
    };

    const options = {
        placeholder: "loading...",
        maxHeight: "80vh"
    }

    return (
        <TabulatorWrapper onClick={() => props.setOpen(false)}>
            <button onClick={() => {
                setUsername("")
                setPassword("")
                usersAddRef.current.show()
            }}>Add new user</button>
            <div style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <ReactTabulator
                    rowClick={(e, row) => {
                        setUsername(row._row.data.username)
                        setPassword("")
                        usersEditRef.current.show()
                    }}
                    options={options}
                    data={data} columns={goRadialAppUsersColumns} />
            </div>
            <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked ref={usersAddRef} title="Add new user">
                <div style={{ textAlign: "center" }}>
                    <input onChange={handleUsernameChange} style={{ width: "80%", marginTop: "100px", marginBottom: "20px" }} placeholder="Username" value={username} />
                    <input onChange={handlePasswordChange} style={{ width: "80%", marginBottom: "20px" }} type="password" placeholder="Password" value={password} />
                    <button onClick={handleCreateUser}>Create user</button>
                </div>
            </SkyLight>
            <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked ref={usersEditRef} title="Edit user">
                <div style={{ textAlign: "center" }}>
                    <label style={{ width: "80%", marginTop: "100px", marginBottom: "20px" }} >{username}</label>
                    <input onChange={handlePasswordChange} style={{ width: "80%", marginBottom: "20px" }} type="password" placeholder="Password" value={password} />
                    <button onClick={handleEditUser} style={{ width: "80%" }}>Update password</button>
                    <button onClick={handleDeleteUser} style={{ width: "80%", backgroundColor: "red" }}>Delete user</button>
                </div>
            </SkyLight>
        </TabulatorWrapper>
    )
}

export default GoRadialAppUsers
