import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
}
const Container = styled.div`
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  font-size: 26px;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

const DropZone = ({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles }) => {

  // const {
  //   getRootProps,
  //   getInputProps,
  //   isDragActive,
  //   isDragAccept,
  //   isDragReject,
  //   acceptedFiles
  // } = useDropzone({ accept: 'application/pdf' });

  const files = acceptedFiles.map(file => (
    <div key={file.path}>
      {file.path}
    </div>
  ));



  return (
    <div className="container">
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>{acceptedFiles.length > 0 ? files : "Drag and drop file here"}</p>
      </Container>
      {/* <h4>Files</h4>
      <ul>{files}</ul> */}
    </div>
  );
}

export default DropZone
