import React from 'react'
import ImageGrid from './ImageGrid'
import ProjectsMap from './ProjectsMap'
import ProjectsMapCircle from './ProjectsMapCircle'
import styled from 'styled-components'
import Navbar from './Navbar'
import NavBarComponent from './NavBarComponent'
import MapBoxMapCluster from './MapBoxMapCluster'

const Main = styled.div`
height: 95vh;
padding-top: 5vh;
`


const Library = (props) => {
    return (
        <>
            {/* <NavBarComponent handleChange={props.handleChange} handleClick={props.handleClick} counter={props.counter}/> */}
            {/* <Navbar handleChange={props.handleChange} handleClick={props.handleClick} counter={props.counter}/> */}
            {/* <Main>{props.pageMode === "card" ? <ImageGrid data={props.data} searchTerm={props.searchTerm} selectedProject={props.selectedProject} setSelectedProject={props.setSelectedProject} /> : <ProjectsMap data={props.data} selectedProject={props.selectedProject} setSelectedProject={props.setSelectedProject} />}</Main> */}
            <Main onClick={() => props.setOpen(false)}>{props.pageMode === "card" ? <ImageGrid data={props.data} searchTerm={props.searchTerm} selectedProject={props.selectedProject} setSelectedProject={props.setSelectedProject} /> : <MapBoxMapCluster data={props.data} selectedProject={props.selectedProject} setSelectedProject={props.setSelectedProject} />}</Main>

        </>
    )
}

export default Library
