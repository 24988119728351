import React, { useState, useRef, useEffect } from "react";
import ReactMapGL, { Marker, FlyToInterpolator, Popup } from "react-map-gl";
import useSupercluster from "use-supercluster";
import styled, { keyframes } from "styled-components"
import { withRouter } from "react-router-dom";

const Container = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, 250px);
grid-auto-rows: minmax(200px,3fr);
gap: 20px;
justify-content: center;
/* padding-top: 30px;
padding-bottom: 20px; */
text-align: center;
  `

const slidein = keyframes`
from {
  opacity: 0
}

to {
  opacity: 1
}
`

const Card = styled.div`
background-color: white;
padding-bottom: 5px;
transition: filter 200ms;
/* box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.1); */
/* border-radius: 3px; */
animation-duration: 0.5s;
animation-name: ${slidein};
&:hover {
  filter: brightness(1.2);
  cursor: pointer;
}
`

const ArchitectTextDiv = styled.div`
position: absolute;
bottom: 2px;
text-align: center;
width: 100%;
color: rgba(0,0,0,0.4);
`

const MapBoxMapCluster = (props) => {
    const [viewport, setViewport] = useState({
        latitude: -33.8615,
        longitude: 151.1767,
        width: "100vw",
        height: "95vh",
        zoom: 12
    });
    const mapRef = useRef();

    const points = props.data.map(project => ({
        type: "Feature",
        properties: { cluster: false, label: project.label, title: project.title, architect: project.architect, longitude: project.longitude, latitude: project.latitude, photo_url: project.photo_url },
        geometry: {
            type: "Point",
            coordinates: [
                parseFloat(project.longitude),
                parseFloat(project.latitude)
            ]
        }
    }));

    const bounds = mapRef.current
        ? mapRef.current
            .getMap()
            .getBounds()
            .toArray()
            .flat()
        : null;

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    useEffect(() => {
        props.setSelectedProject(null)

    }, [])

    return (
        <div>
            <ReactMapGL
                {...viewport}
                maxZoom={17}
                minZoom={4}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN}
                onViewportChange={newViewport => {
                    setViewport({ ...newViewport });
                }}
                ref={mapRef}
                mapStyle="mapbox://styles/radialsolutions/ckmg4szeg57ef17ody7mxv289"
            >
                {clusters.map(cluster => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                        cluster: isCluster,
                        point_count: pointCount
                    } = cluster.properties;

                    if (isCluster) {
                        return (
                            <Marker
                                key={`cluster-${cluster.id}`}
                                latitude={latitude}
                                longitude={longitude}
                            >
                                <div
                                    className="cluster-marker"
                                    style={{
                                        width: `${10 + (pointCount / points.length) * 20}px`,
                                        height: `${10 + (pointCount / points.length) * 20}px`
                                    }}
                                    onClick={() => {
                                        const expansionZoom = Math.min(
                                            supercluster.getClusterExpansionZoom(cluster.id),
                                            20
                                        );

                                        setViewport({
                                            ...viewport,
                                            latitude,
                                            longitude,
                                            zoom: expansionZoom,
                                            transitionInterpolator: new FlyToInterpolator({
                                                speed: 1
                                            }),
                                            transitionDuration: "auto"
                                        });
                                    }}
                                >
                                    {pointCount}
                                </div>
                            </Marker>
                        );
                    }

                    return (
                        <div
                            onMouseOver={() => props.setSelectedProject(cluster.properties)}
                            onMouseOut={() => props.setSelectedProject(null)}
                        >
                            <Marker
                                key={`crime-${cluster.properties.label}`}
                                latitude={latitude}
                                longitude={longitude}
                                onDoubleClick={() => {
                                    props.setSelectedProject(cluster.properties)
                                    props.history.push(`/workmap/${cluster.properties.label.split(" - ")[0]}`)
                                  }}
                            // onClick={() => props.setSelectedProject(cluster.properties)}
                            >
                                <div className="cluster-marker" style={{
                                    width: `${20}px`,
                                    height: `${20}px`,
                                    // opacity: 0.6

                                }}>
                                    1
                            {/* <img src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" alt=""/> */}
                                </div>
                            </Marker>
                        </div>
                    );
                })}
                {props.selectedProject && <Popup
                    latitude={props.selectedProject.latitude}
                    longitude={props.selectedProject.longitude}
                    closeButton={false}
                    closeOnClick={false}
                    // onClose={() => togglePopup(false)}
                    anchor="bottom-right"
                    dynamicPosition={true}
                >
                    
                    <Container>
                    <Card  key={props.selectedProject.value}><img src={props.selectedProject.photo_url} alt="central images" width="250" height="200" /><div>{props.selectedProject.label}</div><small><ArchitectTextDiv >{props.selectedProject.architect}</ArchitectTextDiv></small></Card>
                    </Container>

                    {/* <div style={{ backgroundColor: "white" }}>
                        <img width="200px" height="200px" src={props.selectedProject.photo_url} alt='' />
                        <big><p>{props.selectedProject.project}</p></big>
                        <p>{props.selectedProject.architect}</p>
                    </div> */}
                </Popup>}
            </ReactMapGL>
        </div>
    );
}

export default withRouter(MapBoxMapCluster)
