let tasksColumns = [
    { title: "Task", field: "task", width: 200, sorter: "string", headerFilter: "input" },
    { title: "Task_id_letter", field: "Task_id_letter", visible: false },
    {
        title: "Stage",
        field: "Stage",
        // editor: "select",
        editorParams: {
            values: {
                " ": " ",
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
            },
        },
    },
    {
        title: "Manager",
        field: "Task_manager",
        sorter: "string",
        width: 200,
        // editor: "select",
        headerFilter: "input",
        editorParams: function (cell) {
            // return get_staff_initials(props.currentProjectNumber);
            return "CH";
        },
    },
    {
        title: "Task notes",
        field: "Task_notes",
        //   sorter: "string",
        width: 600,
        widthGrow: 3,
        editor: "textarea",
        formatter: "textarea",
        headerFilter: "input"
    },
    {
        title: "Task Red Button",
        field: "Task_red_button",
        sorter: "string",
        editor: "textarea",
        formatter: "textarea",
        headerFilter: "input"
    },
    {
        title: "Task completed",
        field: "Task_completed",
        sorter: "string",
        // editor: "select",
        editorParams: function (cell) {
            // return get_team_leader_of_edited_column(cell._cell.row.data['task'].split(" ")[0]);
            return "CH";
        },
    },
    {
        title: "Task verified",
        field: "Task_verified",
        sorter: "string",
        // editor: "select",
        editorParams: function (cell) {
            // return get_staff_initials(props.currentProjectNumber);
            return "CH";
        },
    },
    {
        title: "Hidden discipline",
        field: "hidden_discipline",
        sorter: "string",
        visible: false,
    },
]

// let disciplinesColumns = [
//     {
//       title: "Discipline",
//       field: "Discipline",
//       width: 170,
//       widthGrow: 3,
//       headerSort: false,
//       editable: true,
//       editor: "input"
//     },
//     {
//       title: "Team leader",
//       field: "Team_leader",
//       widthGrow: 3,
//       editor: "select",
//       headerSort: false,
//       editorParams:{
//         values: function (cell) {
//           return state.projectStaffInitialsArray.map(reorderStaffInitialsArray)
//         },
//       } 
//     },
//     {
//       title: "Project staff",
//       field: "Project_staff",
//       widthGrow: 3,
//       headerSort: false,
//       editor: "select",
//       editorParams: function (cell) {
//         return state.projectStaffInitialsArray.map(reorderStaffInitialsArray)
//       },
//     },
//     {
//       title: "Stage 0",
//       field: "Stage_0_header",
//       widthGrow: 3,
//       headerSort: false,
//       columns: [
//         {
//           title: "Status",
//           field: "Stage_0",
//           width: 100,
//           headerSort: false,
//           editor: "select",
//           editorParams: {
//             values: status_values,
//           },
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_status(cell, formatterParams, onRendered);
//           },
//         },
//         {
//           title: "Date",
//           field: "Stage_0_date",
//           editor: dateEditor,
//           widthGrow: 3,
//           headerSort: false,
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_date_from_status(cell, formatterParams, onRendered);
//           },
//         },
//       ],
//     },
//     {
//       title: "Stage 1",
//       field: "Stage_1_header",
//       widthGrow: 3,
//       columns: [
//         {
//           title: "Status",
//           field: "Stage_1",
//           width: 100,
//           editor: "select",
//           headerSort: false,
//           editorParams: {
//             values: status_values,
//           },
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_status(cell, formatterParams, onRendered);
//           },
//         },
//         {
//           title: "Date",
//           field: "Stage_1_date",
//           editor: dateEditor,
//           widthGrow: 3,
//           headerSort: false,
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_date_from_status(cell, formatterParams, onRendered);
//           },
//         },
//       ],
//     },
//     {
//       title: "Stage 2",
//       field: "Stage_2_header",
//       widthGrow: 3,
//       columns: [
//         {
//           title: "Status",
//           field: "Stage_2",
//           width: 100,
//           editor: "select",
//           headerSort: false,
//           editorParams: {
//             values: status_values,
//           },
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_status(cell, formatterParams, onRendered);
//           },
//         },
//         {
//           title: "Date",
//           field: "Stage_2_date",
//           editor: dateEditor,
//           widthGrow: 3,
//           headerSort: false,
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_date_from_status(cell, formatterParams, onRendered);
//           },
//         },
//       ],
//     },
//     {
//       title: "Stage 3",
//       field: "Stage_3_header",
//       widthGrow: 3,
//       columns: [
//         {
//           title: "Status",
//           field: "Stage_3",
//           width: 100,
//           headerSort: false,
//           editor: "select",
//           editorParams: {
//             values: status_values,
//           },
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_status(cell, formatterParams, onRendered);
//           },
//         },
//         {
//           title: "Date",
//           field: "Stage_3_date",
//           editor: dateEditor,
//           widthGrow: 3,
//           headerSort: false,
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_date_from_status(cell, formatterParams, onRendered);
//           },
//         },
//       ],
//     },
//     {
//       title: "Stage 4",
//       field: "Stage_4_header",
//       widthGrow: 3,
//       columns: [
//         {
//           title: "Status",
//           field: "Stage_4",
//           width: 100,
//           headerSort: false,
//           editor: "select",
//           editorParams: {
//             values: status_values,
//           },
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_status(cell, formatterParams, onRendered);
//           },
//         },
//         {
//           title: "Date",
//           field: "Stage_4_date",
//           editor: dateEditor,
//           widthGrow: 3,
//           headerSort: false,
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_date_from_status(cell, formatterParams, onRendered);
//           },
//         },
//       ],
//     },
//     {
//       title: "Stage 5",
//       field: "Stage_5_header",
//       widthGrow: 3,
//       headerSort: false,
//       columns: [
//         {
//           title: "Status",
//           field: "Stage_5",
//           width: 100,
//           headerSort: false,
//           editor: "select",
//           editorParams: {
//             values: status_values,
//           },
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_status(cell, formatterParams, onRendered);
//           },
//         },
//         {
//           title: "Date",
//           field: "Stage_5_date",
//           editor: dateEditor,
//           widthGrow: 3,
//           headerSort: false,
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_date_from_status(cell, formatterParams, onRendered);
//           },
//         },
//       ],
//     },
//     {
//       title: "Stage 6",
//       field: "Stage_6_header",
//       widthGrow: 3,
//       columns: [
//         {
//           title: "Status",
//           field: "Stage_6",
//           headerSort: false,
//           width: 100,
//           widthGrow: 3,
//           editor: "select",
//           editorParams: {
//             values: status_values,
//           },
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_status(cell, formatterParams, onRendered);
//           },
//         },
//         {
//           title: "Date",
//           field: "Stage_6_date",
//           editor: dateEditor,
//           widthGrow: 3,
//           headerSort: false,
//           formatter: function (cell, formatterParams, onRendered) {
//             return colour_date_from_status(cell, formatterParams, onRendered);
//           },
//         },
//       ],
//     },
//   ] //define table columns

export const goRadialAppLoginsColumns = [
    { title: "Username", field: "username" },
    { title: "Login time (UTC)", field: "time_accessed" },
];

export const goRadialAppUsersColumns = [
    { title: "Username", field: "username" }
];

export const goRadialAppSearchesColumns = [
    { title: "Username", field: "username", headerFilter: "input" },
    { title: "Search text", field: "searchtext", headerFilter: "input" },
    { title: "Time of search (UTC)", field: "time_accessed" },
];

export const missingLatLonColumns = [
    { title: "Project Number", field: "project_number", headerFilter: "input", width: 150 },
    { title: "Project title", headerFilter: "input", field: "project_title", editor: "input" },
    {
        title: "Latitude", field: "latitude", editor: "input", editorParams: {
            search: true,
            elementAttributes: {
                maxlength: "10", //set the maximum character length of the input element to 10 characters
            }
        }
    },
    {
        title: "Longitude", field: "longitude", editor: "input", editorParams: {
            search: true,
            elementAttributes: {
                maxlength: "10", //set the maximum character length of the input element to 10 characters
            }
        }
    },
];

export const transcribeColumns = [
    { title: "ID", field: "id", visible: false },
    { title: "Transcriptions", field: "transcription_text", headerFilter: "input" },
];

export const drawingReaderColumns = [
    { title: "Word to search", field: "word" },
    { title: "Found", field: "found" },
];

export const documentUploadColumns = [
    { title: "Document URL", field: "url", headerFilter: "input", formatter: "link", formatterParams: { target: "_blank" } },
];

export default tasksColumns;