import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

const Nav = styled.div`
display: flex;
background: linear-gradient(to right, #642B73 , #C6426E);
justify-content: space-between;
padding-top: 5px;
padding-bottom: 5px;
height: 5vh;
align-items: center;
`

const Logo = styled.div`
display: flex;
align-items: center;
`

const RightNav = styled.div`
display: flex;
align-items: center;
`

const NavItem = styled.div`
padding-left: 10px;
color: white;
`

const NavInput = styled.input`
margin-left: 10px;
`

const NavButton = styled.button`
margin-left: 10px;
`


const Navbar = (props) => {
    return (
        <Nav onClick={() => props.setOpen(false)}>
            <Logo >
                <NavInput id="searchTextBox" onChange={props.handleChange} placeholder="Search for..."></NavInput>
                {/* <NavButton onClick={props.handleClick}>Toggle card/map view</NavButton> */}
                <NavButton onClick={() => props.history.push('/login')}>Toggle crd/mew</NavButton>
                <NavItem>{props.counter}</NavItem>
            </Logo>
            <RightNav onClick={() => props.setOpen(false)}>
                <NavItem></NavItem>
                <NavItem><img onClick={() => window.open("https://cantileverstudio.com.au", '_blank')} height="30px" src="https://radial-images.s3.amazonaws.com/documents/cantilever.png" alt='' /></NavItem>
                <NavItem><img height="30px" src="https://radial-images.s3.amazonaws.com/documents/icon.png" alt='' /></NavItem>
                <NavItem><a style={{ color: "rgba(255,255,255,0.6)", paddingRight: "10px" }} href="https://radialsolutions.com.au" target="_blank" rel="noopener noreferrer">Powered by Radial</a></NavItem>
            </RightNav>
        </Nav>
    )
}

export default withRouter(Navbar)
