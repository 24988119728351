import React, { useState } from 'react'
import styled from 'styled-components'
import DropZone from './DropZone'
import SearchWordsList from './SearchWordsList'
import { useDropzone } from 'react-dropzone';
import { ReactTabulator } from 'react-tabulator';
import { drawingReaderColumns } from './utils/tabulator-columns'
import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from './utils/aws-exports';
Amplify.configure(awsmobile)

const HeaderWrapper = styled.div`
 margin: 0 auto;
 padding-top: 8vh;
 display: flex;
justify-content: center;
align-content: center;
flex-direction: column;
`

const ButtonWrapper = styled.div`
display: flex;
justify-content: center;
`

const StyledButton = styled.button`
 width: 205px;
margin-top: 10px;
margin-left: 15px;
`

const TabulatorWrapper = styled.div`
width: 65%;
 margin: 0 auto;
 padding-top: 20px;
`

const DrawingQA = (props) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({ accept: 'application/pdf' });

  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const [wordsList, setWordsList] = useState([])
  const [file, setFile] = useState(acceptedFiles[0])
  const [projectNumber, setProjectNumber] = useState("03010")
  const [tableData, setTableData] = useState([])

  const files = acceptedFiles.map(file => {
    return (
      <div key={file.path}>
        {file.path}
      </div>
    )
  }

  );



  // const handleDrop = (e) => {
  //   e.preventDefault()
  //   setIsDraggingOver(false)

  // }

  // const handleDragOver = (e) => {
  //   e.preventDefault()
  //   setIsDraggingOver(true)
  // }

  async function callBackendAPIWithKey(url, authToken) {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
      },
    });
    try {
      const body = await response.json();
      return body;
    }
    catch {
      return 0
    }

  }

  const handleClick = () => {
    Auth.currentSession().then(sessionData => {
      callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/python-drawings-reader-get-presigned-url?client_method=put&file_name=pdfs/${projectNumber}/${acceptedFiles[0].path}`, sessionData.idToken.jwtToken).then(
        (res) => {
          console.log(res.url)
          fetch(res.url, {
            method: "PUT",
            body: acceptedFiles[0],
            headers: { "content-type": "application/pdf" }
          }).then(res => {
            console.log(res)
            fetch("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/python-drawings-reader-run-drawing-reader", {
              method: "POST",
              body: JSON.stringify({ "words": wordsList, "projectNumber": projectNumber, "fileName": acceptedFiles[0].path }),
              headers: { Authorization: sessionData.idToken.jwtToken, }
            }).then(res => res.json().then(data => setTableData(data)))
          })
        }
      ).catch(() => {
      })
    })
  }

  return (
    <HeaderWrapper onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()}>
      <DropZone getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive} isDragAccept={isDragAccept} isDragReject={isDragReject} acceptedFiles={acceptedFiles} />
      {/* <DropZone /> */}
      <SearchWordsList wordsList={wordsList} setWordsList={setWordsList} />
      <ButtonWrapper>{acceptedFiles[0] && wordsList.length > 0 && <StyledButton onClick={handleClick}>Search PDF</StyledButton>}</ButtonWrapper>
      <TabulatorWrapper><ReactTabulator columns={drawingReaderColumns} data={tableData} /></TabulatorWrapper>
    </HeaderWrapper>
  )
}

export default DrawingQA
