import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker, InfoBox } from '@react-google-maps/api';
import {withRouter} from 'react-router-dom'
const containerStyle = {
  width: '100%',
  height: '95vh'
};

const center = {
  lat: -33.8615, lng: 151.1767
};

function ProjectsMap(props) {

  document.title = "Library - Map";

  const [isProjectSelected, setIsProjectSelected] = useState(false);

  const mapOptions = {
    gestureHandling: "greedy",
    streetViewControl: false
  }

  console.log(props.data)

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={13}
        streetView={false}
        options={mapOptions}
      >
        {props.data.map(project => (
          <Marker
            key={project.value}
            position={{"lat": project.latitude, "lng": project.longitude}}
            onDblClick={() => {
              props.setSelectedProject(project)
              setIsProjectSelected(false)
              props.history.push(`/workmap/${project.value}`)
            }}
            icon={`https://maps.google.com/mapfiles/ms/icons/${project.colour}-dot.png`}
            onMouseOver={() => {
              props.setSelectedProject(project)
              setIsProjectSelected(true)
            }}
            onMouseOut={() => {
              props.setSelectedProject(null)
              setIsProjectSelected(true)
            }}
            onMouseDown={() => props.setSelectedProject(project)}
            onMouseUp={() => props.setSelectedProject(null)}
          />
        )
        )}
        {isProjectSelected && props.selectedProject  && (
          <InfoBox
          position={{"lat": props.selectedProject.latitude, "lng": props.selectedProject.longitude}}
          closeBoxURL="">
            <div style={{backgroundColor: "white"}}>
            <img width="200px" height="200px" src={props.selectedProject.photo_url} alt=''/>
            <big><p>{props.selectedProject.label}</p></big>
            <p>{props.selectedProject.architect}</p>
            </div>
          </InfoBox>
        )}
        { /* Child components, such as markers, info windows, etc. */}
        <></>
      </GoogleMap>
    </LoadScript>
  )
}

export default withRouter(React.memo(ProjectsMap))