import React, { useState, useEffect } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import Amplify, { Auth } from "aws-amplify";
import { awsmobile } from "./utils/aws-exports";
import styled from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { ReactTabulator } from "react-tabulator";
import { transcribeColumns } from "./utils/tabulator-columns";
import {
  XYPlot,
  LineSeries,
  YAxis,
  XAxis,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeries,
  LabelSeries,
  HorizontalBarSeries,
  DiscreteColorLegend,
} from "react-vis";
import "react-vis/dist/style.css";
Amplify.configure(awsmobile);

const HeaderWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding-top: 8vh;
`;

const RecordWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding-top: 4vh;
  display: flex;
  align-items: center;
`;

const TabulatorWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding-top: 4vh;
  align-items: center;
`;

const RecordButton = styled.button`
  /* background-color: red; */
  color: white;
  border-radius: 50%;
  outline: 0 !important;
  background-color: ${(props) => (props.isRecording ? "blue" : "red")};
  min-width: 70px;
  min-height: 70px;
`;

const Mp3Recorder = new MicRecorder({ bitRate: 320 });

const StaffPlots = (props) => {
  const [audioFileBlobUrl, setAudioFileBlobUrl] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordLive, setRecordLive] = useState(false);
  const [data, setData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [staffGoals, setStaffGoals] = useState([]);
  const [staffInitials, setstaffInitials] = useState("MWM");
  const [toggleChecked, setToggleChecked] = useState(true);
  const [goalsColour, setGoalsColour] = useState("#CFCFCF");
  const [capabilitiesColour, setCapabilitiesColour] = useState("#FAC753");
  const [yDomain, setYDomain] = useState([]);

  const greenData = [
    { x: "Wind", y: 10 },
    { x: "B", y: 5 },
    { x: "C", y: 15 },
    { x: "D", y: 10 },
    { x: "E", y: 5 },
    { x: "F", y: 15 },
    { x: "G", y: 15 },
    { x: "H", y: 15 },
    { x: "I", y: 15 },
    { x: "J", y: 15 },
    { x: "K", y: 15 },
    { x: "L", y: 15 },
    { x: "M", y: 15 },
    { x: "N", y: 15 },
    { x: "O", y: 15 },
    { x: "P", y: 15 },
  ];

  const blueData = [
    { x: "Wind", y: 12 },
    { x: "B", y: 2 },
    { x: "C", y: 11 },
    { x: "D", y: 10 },
    { x: "E", y: 5 },
    { x: "F", y: 15 },
    { x: "G", y: 15 },
    { x: "H", y: 15 },
    { x: "I", y: 15 },
    { x: "J", y: 15 },
    { x: "K", y: 15 },
    { x: "L", y: 15 },
    { x: "M", y: 15 },
    { x: "N", y: 15 },
    { x: "O", y: 15 },
    { x: "P", y: 15 },
  ];

  async function callBackendAPIWithKey(url, authToken) {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
      },
    });
    try {
      const body = await response.json();
      return body;
    } catch {
      return 0;
    }
  }

  const handleUploadClick = () => {
    Auth.currentSession().then((sessionData) => {
      callBackendAPIWithKey(
        `https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getstaffcapabilities?staffInitials=${staffInitials}`,
        sessionData.idToken.jwtToken
      )
        .then((res) => {
          let staffCapabilities = res.map((task) => ({
            x: task.capabilities,
            y: task.task,
          }));
          let staffGoals = res.map((task) => ({ x: task.goals, y: task.task }));
          // console.log(res.reverse())
          let fullArrayUnsorted = [...staffCapabilities, ...staffGoals];
          let fullArray = toggleChecked
            ? fullArrayUnsorted
            : [
                ...staffCapabilities.filter((task) => task.x !== 0),
                ...staffGoals.filter((task) => task.x !== 0),
              ];
          setYDomain(
            [...new Set(fullArray.map((task) => task.y))].sort().reverse()
          );
          setStaffData(staffCapabilities);
          setStaffGoals(staffGoals);
        })
        .catch(() => {});
    });
  };

  useEffect(() => {
    handleUploadClick();
  }, [staffInitials, toggleChecked]);

  const options = {
    placeholder: "loading...",
    maxHeight: "80vh",
  };

  const legendItems = [
    {
      title: "Capabilities",
      color: "#FAC753",
      strokeWidth: 6,
    },
    {
      title: "Individual goals",
      color: "#CFCFCF",
      strokeWidth: 6,
    },
  ];

  return (
    <div style={{ marginLeft: "0px" }} onClick={() => props.setOpen(false)}>
      <HeaderWrapper>
        <select onChange={(e) => setstaffInitials(e.target.value)}>
          <option value="ADS">Adam</option>
          <option value="DSH">Damian</option>
          <option value="LRM">Lemuel</option>
          <option value="RIE">Riki</option>
          <option value="TOP">Tom</option>
          <option value="MTP">Mark</option>
          <option value="KLC">Kerrie</option>
          <option value="CZ">Charles</option>
          <option value="BAM">Bill</option>
          <option value="GNI">Geoffrey</option>
          <option value="JDW">Jonathan</option>
          <option value="LSK">Layla</option>
          <option value="VK">Vivienne</option>
          <option value="TV">Teerapol</option>
          <option value="SZE">Sunni</option>
        </select>
        <br />
        <input
          onChange={(e) => setToggleChecked(e.target.checked)}
          checked={toggleChecked}
          type="checkbox"
          id="Show_hide_zero_tasks"
          name="Show_hide_zero_tasks"
        />
        <label for="Show_hide_zero_tasks"> Show/hide new tasks</label>
        <br />
        <br />
        <XYPlot
          yDomain={staffGoals ? yDomain : null}
          animation={true}
          yType="ordinal"
          width={0.9 * window.innerWidth}
          height={
            toggleChecked ? 3 * window.innerHeight : 2 * window.innerHeight
          }
        >
          <DiscreteColorLegend
            style={{
              position: "absolute",
              left: window.innerWidth * 0.75,
              top: "-80px",
              fontSize: 20,
            }}
            items={legendItems}
            orientation="horizontal"
          />
          <VerticalGridLines
            tickFormat={(val) => (Math.round(val) === val ? val : "")}
          />
          <XAxis
            orientation="bottom"
            tickFormat={(val) => (Math.round(val) === val ? val : "")}
          />
          <XAxis
            orientation="top"
            tickFormat={(val) => (Math.round(val) === val ? val : "")}
          />
          <XAxis
            top={-13}
            tickFormat={(val) => (Math.round(val) === val ? val : "")}
          />

          {/* <VerticalBarSeries color={"#424B54"} className="vertical-bar-series-example" data={greenData} />
                    <VerticalBarSeries color={"#C5BAAF"} data={blueData} /> */}
          <HorizontalBarSeries
            barWidth={0.7}
            color={capabilitiesColour}
            data={
              toggleChecked
                ? staffData
                : staffData.filter((task) => task.x !== 0)
            }
          />
          <HorizontalBarSeries
            barWidth={0.7}
            color={goalsColour}
            data={
              toggleChecked
                ? staffGoals
                : staffGoals.filter((task) => task.x !== 0)
            }
          />
          <YAxis
            style={{
              text: {
                fontSize: 20,
                fontWeight: 100,
                textAnchor: "start",
                transform: "translate(10px, 0px)",
              },
            }}
          />
          {/* <HorizontalGridLines /> */}

          {/* <LabelSeries data={labelData} getLabel={d => d.x} /> */}
        </XYPlot>
      </HeaderWrapper>
    </div>
  );
};

export default StaffPlots;
